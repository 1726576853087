
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
@Component
export default class AddTypes extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '' }) private id!: string
  private formInfo = this.formInfoBak

  get formInfoBak () {
    return {
      assetRightName: ''
    }
  }

  @Watch('id')
  getId (val: string) {
    val && this.getDetail(val)
  }

  private rules = {
    assetRightName: [
      { required: true, message: '请输入产权名称', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  getDetail (val: string) {
    this.$axios.get(this.$apis.assetScheduling.selectAssetRightByAssetRight, { assetRight: val }).then(res => {
      this.formInfo = res || []
    })
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.id ? this.$apis.assetScheduling.updateAssetRight : this.$apis.assetScheduling.insertAssetRight, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.formInfo = this.formInfoBak
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).resetFields()
    })
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }
}
